<template>
  <loader
    v-if="isLoading"
    message="Loading ...."
  />
  <div v-else>
    <v-container class="pa-0">
      <v-row class="pa-1 ma-0 my-3" style="background: #e0f4ff; border-radius: 8px;">
        <v-col class="d-flex flex-column py-0" cols="1">
          <s-text weight="medium" color="gray" size="sm">
            Type
          </s-text>
        </v-col>
        <v-col class="d-flex flex-column py-0" cols="3">
          <s-text weight="medium" color="gray" size="sm">
            Amount
          </s-text>
        </v-col>
        <v-col class="d-flex flex-column py-0" cols="3">
          <s-text weight="medium" color="gray" size="sm">
            Status
          </s-text>
        </v-col>
        <v-col class="d-flex flex-column py-0" cols="4">
          <s-text weight="medium" color="gray" size="sm">
            Date Sent
          </s-text>
        </v-col>
      </v-row>
      <v-row
        v-for="log in logs"
        :key="log.id"
        class="pa-0 ma-0 mb-3 app-card app-card-content"
        @click="openLogDetails(log)"
      >
        <v-col class="d-flex flex-column" cols="1">
          <div>
            {{ log.type }}
          </div>
        </v-col>
        <v-col class="d-flex flex-column" cols="3">
          <s-link
            weight="medium"
            color="grayTextAlt"
            :to="{ name: 'appDetails', params: { id: log.from } }"
          >
            {{ log.currency }} {{ getCurrencyFormatted(log.amount) }}
          </s-link>
        </v-col>
        <v-col class="d-flex flex-column" cols="3">
          <s-link
            weight="medium"
            color="grayTextAlt"
            :to="{ name: 'appDetails', params: { id: log.to } }"
          >
            {{ log.status }}
          </s-link>
        </v-col>
        <v-col class="d-flex flex-column" cols="4">
          <div>
            {{ log.created_at ? getHumanReadableDate(log.created_at) : "-" }}
          </div>
        </v-col>
        <v-col class="action_group d-flex align-center flex-row-reverse" cols="1">
          <s-icon name="arrow-circle-right" class="mr-2" />
        </v-col>
      </v-row>
    </v-container>
    <s-dialog
      v-model="showModal"
      persistent
      isModal
      title="Transaction Details"
    >
      <view-txn-details
        v-if="showModal"
        :id="id"
        :txn-id="txnId"
      />
    </s-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ViewTxnDetails from '@/components/apps/ViewTxnDetails'
import Loader from '@/components/cards/Loader'
import { getHumanReadableDate } from '@/utils/date'
import { getCurrencyFormatted } from '@/utils/fmt'
export default {
  name: 'AppLogs',
  components: {
    loader: Loader,
    'view-txn-details': ViewTxnDetails,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    txnId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      logs: 'user/currentUserWalletTxns',
    }),
  },
  data () {
    return {
      isLoading: false,
      showModal: this.$route.meta.showModal,
      log: null,
    }
  },
  methods: {
    getHumanReadableDate,
    getCurrencyFormatted,
    getLogs () {
      this.isLoading = true
      this.$store
        .dispatch('user/getUserWalletTxns', this.id)
        .finally(() => {
          this.isLoading = false
        })
    },
    openLogDetails (log) {
      this.$router.push({ name: 'appWalletTxnDetail', params: { id: this.id, txnId: log.txn_ref } })
    },
  },
  watch: {
    '$route.meta' ({ showModal }) {
      this.showModal = showModal
    },
    logs: {
      handler () {
        if (this.logs) return
        this.getLogs()
      },
      immediate: true,
    },
  },
}
</script>
